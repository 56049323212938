<template>
  <div id="printable">
    <div class="card">
      <div class="card-body">
        <!-- REPORT HEADER -->
        <section class="company">
          <section class="logo row">
            <div class="col-md-6 col-12 d-flex justify-content-start">
              <img :src="company.Logo" height="50">
              <span class="text ml-2 my-auto">{{ company.Name }}</span>
            </div>
          </section>
          <section class="address mt-1">
            {{ company.Address }}
          </section>
        </section>

        <!-- REPORT TITLE -->
        <h3 class="text-center my-2 font-weight-bolder">LABORATORY TEST RESULTS</h3>

        <!-- REPORT INFO -->
        <section class="report-info">
          <table class="report-info-value">
            <tr>
              <th>Date</th>
              <td v-html="': ' + information.Date"></td>
            </tr>
            <tr>
              <th>Medrec No </th>
              <td v-html="': ' + information.Medrec"></td>
            </tr>
            <tr>
              <th>Patient</th>
              <td v-html="': ' + information.Patient"></td>
            </tr>
            <tr>
              <th>Medical Personnel</th>
              <td v-html="': ' + information.Staff"></td>
            </tr>
          </table>
        </section>

        <!-- TEST LIST -->
        <section class="test-list mt-3">
          <table class="table table-bordered table-hover table-striped">
            <thead class="thead-dark text-center">
              <tr>
                <th width="20">#</th>
                <th>Test</th>
                <th>Result</th>
                <th>Unit</th>
                <th>Reference Value</th>
              </tr>
            </thead>
            <tbody v-if="tests.length > 0">
              <tr v-for="(item, index) in tests" v-bind:key="item.Oid">
                <td class="text-center">{{ index + 1 }}</td>
                <td v-html="item.Test"></td>
                <td v-html="item.Result"></td>
                <td>{{ item.Unit }}</td>
                <td>{{ item.ReferenceValue }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="6">No Test Yet</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      company: {},
      information: {},
      tests: [],
    }
  },

  created() {
    this.load()
  },

  methods: {
    load() {
      this.loaderState(true)
      this.$http.get(`registration/labtrx/result/${this.$route.params.oid}`)
      .then(res => {
        this.company = res.data.Company
        this.information = res.data.Information
        this.tests = res.data.Tests

        this.loaderState(false)
        setTimeout(()=>{
          this.print()
        },1000)
      })
      .catch(err => this.loaderState(false))
    },

    print() {
      let printContents = document.getElementById('printable').innerHTML
      document.body.style.margin = "0"
      document.body.innerHTML = printContents
      window.print()
      window.close()
    },
  },
}
</script>
<style scoped>
  .company .logo .text {
    font-size: 15pt;
    font-weight: bold;
  }
  .company .address {
    white-space: pre-wrap;
  }
</style>
